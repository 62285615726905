import type { SVGProps } from "react";
const IconMoonRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12.115 21.749q-.72 0-1.454-.104c-4.389-.626-7.818-4.105-8.34-8.46a9.6 9.6 0 0 1 1.306-6.151C5.401 4.083 8.66 2.25 12.133 2.25c.471.001.871.279 1.03.708.161.433.038.908-.313 1.21-1.578 1.358-2.284 3.401-1.887 5.464.46 2.398 2.434 4.231 4.91 4.562 1.497.199 2.938-.136 4.159-.974a1.09 1.09 0 0 1 1.252.006c.376.263.545.72.432 1.163-1.123 4.39-5.111 7.359-9.601 7.36m-1.03-17.933c-2.549.321-4.85 1.789-6.173 3.991a8.13 8.13 0 0 0-1.102 5.199c.441 3.682 3.345 6.623 7.062 7.154 3.944.562 7.67-1.614 9.07-5.157a7.5 7.5 0 0 1-4.27.679c-3.117-.416-5.602-2.734-6.184-5.766-.426-2.227.166-4.435 1.597-6.1M4.27 7.42h.01z"
    />
  </svg>
);
export default IconMoonRegular;
