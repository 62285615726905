import type { SVGProps } from "react";
const IconMobileRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M16 21.75H8C5.582 21.75 4.25 20.418 4.25 18V6C4.25 3.582 5.582 2.25 8 2.25H16C18.418 2.25 19.75 3.582 19.75 6V18C19.75 20.418 18.418 21.75 16 21.75ZM8 3.75C6.423 3.75 5.75 4.423 5.75 6V18C5.75 19.577 6.423 20.25 8 20.25H16C17.577 20.25 18.25 19.577 18.25 18V6C18.25 4.423 17.577 3.75 16 3.75H8ZM14.25 6C14.25 5.586 13.914 5.25 13.5 5.25H10.5C10.086 5.25 9.75 5.586 9.75 6C9.75 6.414 10.086 6.75 10.5 6.75H13.5C13.914 6.75 14.25 6.414 14.25 6ZM12 17C11.448 17 11 17.448 11 18C11 18.552 11.448 19 12 19C12.552 19 13 18.552 13 18C13 17.448 12.552 17 12 17Z"
      fill="currentColor"
    />
  </svg>
);
export default IconMobileRegular;
