import type { SVGProps } from "react";
const IconStarQuarterFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20.6999 11.41C21.2899 10.84 20.9598 9.83002 20.1398 9.71002L15.6598 9.06C15.3298 9.01 15.0498 8.81001 14.8998 8.51001L12.9599 4.59998C12.7699 4.19998 12.3799 4 11.9999 4C11.6199 4 11.2298 4.19998 11.0398 4.59998L9.09986 8.51001C8.94986 8.81001 8.66985 9.01 8.33985 9.06L3.85988 9.71002C3.03988 9.83002 2.70982 10.84 3.29982 11.41L6.54981 14.56C6.77981 14.79 6.88984 15.12 6.82984 15.44L6.08986 19.75C5.96986 20.44 6.51979 21 7.14979 21C7.30979 21 7.47979 20.96 7.64979 20.88L11.5298 18.84C11.6798 18.76 11.8399 18.73 11.9999 18.73C12.1599 18.73 12.3199 18.76 12.4699 18.84L16.3499 20.88C16.5199 20.96 16.6899 21 16.8499 21C17.4799 21 18.0298 20.44 17.9098 19.75L17.1698 15.44C17.1098 15.12 17.2199 14.79 17.4499 14.56L20.6999 11.41ZM11.9999 6.04L13.5598 9.18996C13.9298 9.92996 14.6298 10.44 15.4398 10.55L18.9198 11.06L16.4098 13.49C15.8198 14.07 15.5498 14.9 15.6898 15.71L16.2798 19.15L13.1598 17.52C12.7998 17.33 12.3999 17.24 11.9999 17.24L11.8001 17.2475C11.4677 17.2727 11.14 17.3616 10.84 17.52L9.5 18.219L9.50078 10.2155C9.89686 9.97415 10.2244 9.62109 10.44 9.18996L11.9999 6.04Z"
      fill="currentColor"
    />
  </svg>
);
export default IconStarQuarterFilled;
