import type { SVGProps } from "react";
const IconStarFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12.9616 4.59625L14.9036 8.51324C15.0496 8.80824 15.3316 9.01226 15.6586 9.05926L20.1416 9.70721C20.9636 9.82621 21.2916 10.8342 20.6966 11.4122L17.4556 14.5572C17.2186 14.7872 17.1106 15.1182 17.1666 15.4432L17.9076 19.7473C18.0576 20.6203 17.1406 21.2862 16.3546 20.8752L12.4666 18.8403C12.1746 18.6873 11.8266 18.6873 11.5356 18.8403L7.65059 20.8732C6.86359 21.2852 5.94356 20.6182 6.09456 19.7432L6.83565 15.4432C6.89165 15.1182 6.78359 14.7872 6.54659 14.5572L3.30562 11.4122C2.70962 10.8342 3.03755 9.82621 3.86055 9.70721L8.34359 9.05926C8.66959 9.01226 8.95159 8.80824 9.09859 8.51324L11.0406 4.59625C11.4316 3.80125 12.5676 3.80125 12.9616 4.59625Z"
      fill="currentColor"
    />
  </svg>
);
export default IconStarFilled;
