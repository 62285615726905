import type { SVGProps } from "react";
const IconFoodTrayRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M21 16.25h-.35c.06-.42.1-.83.1-1.25 0-4.13-2.88-7.6-6.74-8.51.15-.3.24-.63.24-.99 0-1.24-1.01-2.25-2.25-2.25S9.75 4.26 9.75 5.5c0 .36.09.69.24.99-3.86.91-6.74 4.38-6.74 8.51 0 .42.04.83.1 1.25H3c-.41 0-.75.34-.75.75v1.5c0 1.24 1.01 2.25 2.25 2.25h15c1.24 0 2.25-1.01 2.25-2.25V17c0-.41-.34-.75-.75-.75M11.25 5.5c0-.41.34-.75.75-.75s.75.34.75.75-.34.75-.75.75-.75-.34-.75-.75M4.75 15C4.75 11 8 7.75 12 7.75S19.25 11 19.25 15c0 .41-.05.83-.12 1.25H4.87A7.7 7.7 0 0 1 4.75 15m15.5 3.5c0 .41-.34.75-.75.75h-15c-.41 0-.75-.34-.75-.75v-.75h16.5zm-3.54-6.9a.747.747 0 0 1-.63 1.14c-.25 0-.5-.13-.64-.35-.52-.83-1.5-1.47-2.56-1.66a.75.75 0 0 1-.61-.87.75.75 0 0 1 .87-.61c1.49.26 2.83 1.14 3.58 2.34z"
    />
  </svg>
);
export default IconFoodTrayRegular;
