import type { SVGProps } from "react";
const IconTruckRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M21.697 13.014q.001-.007.003-.014c0-.014-.007-.025-.008-.039a4 4 0 0 0-.041-.204c-.007-.02-.009-.041-.017-.06a4 4 0 0 0-.149-.519l-1.038-2.564c-.459-1.163-.934-2.365-3.478-2.365h-2.22v-1.75c0-1.241-1.01-2.25-2.25-2.25h-8a2.253 2.253 0 0 0-2.25 2.25v11c0 1.165.893 2.114 2.028 2.228-.009.091-.028.179-.028.272a2.753 2.753 0 0 0 2.75 2.75 2.753 2.753 0 0 0 2.75-2.75c0-.086-.018-.167-.025-.25h4.551c-.008.083-.025.164-.025.25a2.753 2.753 0 0 0 2.75 2.75 2.753 2.753 0 0 0 2.75-2.75c0-.093-.018-.182-.028-.272a2.246 2.246 0 0 0 2.028-2.228v-2.92c0-.189-.025-.377-.053-.565M16.97 8.75c1.523 0 1.665.357 2.085 1.421l.841 2.079H14.75v-3.5zM7 20.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25m10 0c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25m2.5-3h-.396a2.73 2.73 0 0 0-2.104-1c-.849 0-1.599.394-2.104 1H9.103a2.73 2.73 0 0 0-2.104-1c-.849 0-1.599.394-2.104 1h-.396a.75.75 0 0 1-.75-.75v-2.75h6.25a.75.75 0 0 0 0-1.5h-6.25V5.5a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v8.25h7v2.75a.75.75 0 0 1-.749.75"
    />
  </svg>
);
export default IconTruckRegular;
