import type { SVGProps } from "react";
const IconCurbsideRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#curbside_regular_svg__a)">
      <path
        fill="currentColor"
        d="M17.34 4.23h-4.57V2.99c0-.43-.35-.78-.77-.78s-.77.35-.77.78v1.24H6.66c-1.34 0-2.43 1.09-2.43 2.43v4.69c0 1.34 1.09 2.43 2.43 2.43h4.57v6.45h-2.2c-.43 0-.77.35-.77.78s.35.78.77.78h5.96c.43 0 .77-.35.77-.78s-.35-.78-.77-.78h-2.21v-6.45h4.57c1.34 0 2.43-1.09 2.43-2.43V6.66c0-1.34-1.09-2.43-2.43-2.43zm.88 7.12c0 .49-.4.88-.88.88H6.66c-.49 0-.88-.39-.88-.88V6.66c0-.49.4-.88.88-.88h10.69c.49 0 .88.39.88.88v4.69z"
      />
    </g>
    <defs>
      <clipPath id="curbside_regular_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default IconCurbsideRegular;
