import type { SVGProps } from "react";
const IconStarHalfFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20.7497 11.41C21.3397 10.84 21.0096 9.83002 20.1896 9.71002L15.7096 9.06C15.3796 9.01 15.0996 8.81001 14.9496 8.51001L13.0097 4.59998C12.8197 4.19998 12.4297 4 12.0497 4C11.6697 4 11.2796 4.19998 11.0896 4.59998L9.14967 8.51001C8.99967 8.81001 8.71966 9.01 8.38966 9.06L3.90969 9.71002C3.08969 9.83002 2.75963 10.84 3.34963 11.41L6.59962 14.56C6.82962 14.79 6.93965 15.12 6.87965 15.44L6.13967 19.75C6.01967 20.44 6.5696 21 7.1996 21C7.3596 21 7.5296 20.96 7.6996 20.88L11.5796 18.84C11.7296 18.76 11.8897 18.73 12.0497 18.73C12.2097 18.73 12.3697 18.76 12.5197 18.84L16.3997 20.88C16.5697 20.96 16.7397 21 16.8997 21C17.5297 21 18.0796 20.44 17.9596 19.75L17.2196 15.44C17.1596 15.12 17.2697 14.79 17.4997 14.56L20.7497 11.41ZM16.4596 13.48C15.8696 14.06 15.5996 14.89 15.7396 15.7L16.3296 19.14L13.2096 17.51C12.8496 17.32 12.4497 17.23 12.0497 17.23V6.03003L13.6096 9.17999C13.9796 9.91999 14.6796 10.43 15.4896 10.54L18.9696 11.05L16.4596 13.48Z"
      fill="currentColor"
    />
  </svg>
);
export default IconStarHalfFilled;
