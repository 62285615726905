import type { SVGProps } from "react";
const IconSignDirectionRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20.237 7.76294L17.383 4.90894C16.958 4.48394 16.393 4.25 15.792 4.25H11.749V3C11.749 2.586 11.413 2.25 10.999 2.25C10.585 2.25 10.249 2.586 10.249 3V4.25H5.84503C4.19503 4.25 3.24902 5.19595 3.24902 6.84595V11.1541C3.24902 12.8041 4.19503 13.75 5.84503 13.75H10.249V20.25H8.00003C7.58603 20.25 7.25003 20.586 7.25003 21C7.25003 21.414 7.58603 21.75 8.00003 21.75H14C14.414 21.75 14.75 21.414 14.75 21C14.75 20.586 14.414 20.25 14 20.25H11.75V13.75H15.793C16.394 13.75 16.959 13.5161 17.384 13.0911L20.238 10.2371C20.919 9.55506 20.919 8.44494 20.237 7.76294ZM19.177 9.177L16.323 12.031C16.181 12.173 15.993 12.251 15.793 12.251H5.84604C5.02604 12.251 4.75003 11.975 4.75003 11.155V6.84692C4.75003 6.02692 5.02604 5.75098 5.84604 5.75098H15.793C15.993 5.75098 16.182 5.82895 16.323 5.97095L19.177 8.82495C19.274 8.92095 19.274 9.079 19.177 9.177Z"
      fill="currentColor"
    />
  </svg>
);
export default IconSignDirectionRight;
